.App {
  text-align: center;
}

body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;

  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

button {
  background-color: rgb(4, 170, 106);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
  cursor: pointer;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#rotatable-image {
  transition: transform 0.5s ease-in-out;
  display: none;

}

.rotate-button {
  margin-top: 10px;
  cursor: pointer;
}
#App-header {
  display: flex;

  align-items: center;
  justify-content: center;
}
#randomidkodas {
  display: none;
}

#TITILE {
  font-size: 5cm;
  display: none;
}

#main_video_player {
  display: none;
}